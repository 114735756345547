import React, { useState, useEffect, useRef } from 'react';
import './Chatbot.css';
import { FaArrowUp, FaStop } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const Chatbot = ({ tenant, initialData }) => {
    const [messages, setMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false); // State to manage bot typing status
    const [sessionId, setSessionId] = useState(null); // State to store session ID [Optional]
    const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage user login status [Optional]
    const [input, setInput] = useState('');
    const [isWaiting, setIsWaiting] = useState(false); // State to manage waiting for bot response
    const chatboxRef = useRef(null);
    const inputRef = useRef(null); // Reference to the input element

    useEffect(() => {
        const existingSessionId = Cookies.get('session_id');
        if (existingSessionId) {
            setSessionId(existingSessionId);
        } else {
            const newSessionId = uuidv4();
            Cookies.set('session_id', newSessionId);
            setSessionId(newSessionId);
        }
        if (initialData) {
            addInitialDataMessage(initialData);
        }
        const accessToken = Cookies.get('access_token_cookie');
        if (accessToken) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        if (chatboxRef.current) {
            chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        if (!isWaiting) {
            inputRef.current.focus(); // Set focus on the input box
        }
    }, [isWaiting]);

    const backendResponse = async (input) => {
        try {
            const response = await axios.post('/chatbot', { message: input, session_id: sessionId, tenant: tenant }, { headers: { 'Content-Type': 'application/json', }, withCredentials: true });
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error(error);
            return "Sorry, I'm having trouble connecting to the server. Please try again later.";
        }
    };

    const handleSend = async (message = input || '') => {
        if (message.trim() === '' || isWaiting) return;

        const userMessage = { text: message, isUser: true };
        setMessages(prevMessages => [...prevMessages, userMessage]);
        setInput('');
        setIsWaiting(true);
        const responseText = await backendResponse(message);
        console.log("the responseText is", responseText);

         // Strip out content within <detail></detail>
        responseText.reply = responseText.reply.replace(/<detail>.*?<\/detail>/gs, '');
        responseText.reply = responseText.reply.replace(/\*\*/g, '');
        responseText.reply = responseText.reply.replace(/^\s*-\s*$/gm, '');

        const formattedText = { text: <span style={{ whiteSpace: 'pre-wrap' }}>{responseText.reply}</span>, isUser: false };
        console.log("the formattedText is", formattedText);
        setMessages(prevMessages => [...prevMessages, formattedText]);
        setIsWaiting(false);
    };

    const handleStop = () => {
        setIsWaiting(false); // Allow user to input again
    };

    const formatTeeTime = (dateAndTime) => {
        const date = new Date(dateAndTime);
        const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' };
        return date.toLocaleString('en-US', options);
    };

    const formatDate = (dateToFormat) => {
        const date = new Date(dateToFormat);
        const options = { month: 'short', day: 'numeric' }//, hour: 'numeric', minute: 'numeric' };
        return date.toLocaleString('en-US', options);
    };
    const formatMilitaryTime = (time) => {
        // Prepend a placeholder date so we can create a Date object
        const date = new Date(`1970-01-01T${time}`);
        
        // Options to display hour in 12-hour format with AM/PM
        const options = { hour: 'numeric', hour12: true };
        
        // Convert to locale string with specified options
        return date.toLocaleString('en-US', options);
      };
    const formatPlayerName = (name) => {
        if (!name || !name.includes(' ')) {
            return "No player";
        }
        const parts = name.split(' ');
        const lastName = parts.pop();
        const firstName = parts.shift();
        return `${firstName[0]}. ${lastName}`;
    };

    const addInitialDataMessage = (data) => {
        const teeTimes = data.teetimes.length > 0 
            ? data.teetimes.map((teeTime, index) => (
                <li key={index}>{formatTeeTime(teeTime.dateAndTime)} on {teeTime.course} with {teeTime.players.split(', ').map(formatPlayerName).join(', ')}</li>
              ))
            : <li>No tee times booked</li>;

        const watchers = data.watchers.length > 0 
            ? data.watchers.map((watcher, index) => (
                <li key={index}>{watcher.watcher.formatted_date} from {watcher.watcher.formatted_start_time} to {watcher.watcher.formatted_end_time} on {watcher.watcher.course} with at least {watcher.watcher.minimum_nbr_players} players</li>
              ))
            : <li>No watchers set</li>;

        const diningReservations = data.dining.length > 0
            ? data.dining.map((dining, index) => <li key={index}>{dining.formatted_datetime} in {dining.booking.title} for {dining.booking.partysize}</li>)
            : <li>No dining reservations</li>;

        const newUserMessage = data.new_user ? <p><strong>Thank you {data.first_name} for signing up for MemberCaddy! Use the input field at the bottom of the screen to ask me what I can do for you!</strong></p> :
            <p><strong>Welcome Back {data.first_name}!</strong></p>

        const initialMessage = (
            <div>
                <div>{newUserMessage}</div>
                <div><p><strong>Here are your: </strong></p></div>
                <div>
                    <strong>Tee Times:</strong>
                    <ul>{teeTimes}</ul>
                </div>
                <div>
                    <strong>Watchers:</strong>
                    <ul>{watchers}</ul>
                </div>
                <div>
                    <strong>Dining Reservations:</strong>
                    <ul>{diningReservations}</ul>
                </div>
            </div>
        );
        
        setMessages([{ text: initialMessage, isUser: false }]);
    };

    return (
        <div className="chatbot-container">
            <div className="chatbox" ref={chatboxRef}>
                {messages.map((message, index) => (
                    <div key={index} className={`message ${message.isUser ? 'user-message' : 'bot-message'}`}>
                        {message.text}
                    </div>
                ))}
                {isWaiting && (
                    <div className="typing-indicator">
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                )}
            </div>
            <div className="input-container">
                <input 
                    type="text" 
                    value={input} 
                    onChange={(e) => setInput(e.target.value)} 
                    placeholder="If you need help, ask me what I can do!"
                    onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                    disabled={isWaiting || isTyping} // Disable input when waiting for bot response
                    ref={inputRef} // Set the input element reference
                />
                <button onClick={isWaiting ? handleStop : () => handleSend(input)}>
                    {isWaiting ? <FaStop /> : <FaArrowUp />}
                </button>
            </div>
        </div>
    );
};

export default Chatbot;