import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Chatbot from './Chatbot';
import Login from './Login';

function App({ tenant }) {
  //const { tenant } = useParams();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [initialData, setInitialData] = useState(null); 

  const handleLogin = (reply) => {
    setInitialData(reply)
    setIsLoggedIn(true);
  };

  console.log("Current tenant:", tenant); // To verify the tenant value

  return (
    <div className="App">
      {isLoggedIn ? <Chatbot tenant={tenant} initialData={initialData} /> : <Login onLogin={handleLogin} tenant={tenant} />}
    </div>
  );
}

export default App;