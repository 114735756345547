import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

function Main() {
  const [tenant, setTenant] = useState('');

  useEffect(() => {
      // Extract tenant from query string
      const urlParams = new URLSearchParams(window.location.search);
      console.log(urlParams)
      const tenantParam = urlParams.get('tenant');
      if (tenantParam) {
        setTenant(tenantParam);
    } else {
    // Extract tenant from subdomain
    const subdomain = window.location.hostname.split('.')[0];
    if (subdomain !== 'www' && subdomain !== 'localhost') {
      setTenant(subdomain);

      }
    }
  }, []);

  return (
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<App tenant={tenant} />} />
        </Routes>
      </Router>
    </React.StrictMode>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Main />);

reportWebVitals();