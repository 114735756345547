import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './Login.css';

const Login = ({ onLogin, tenant}) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoggingIn, setIsLoggingIn] = useState(false); // New state variable
  console.log("Current tenant in login:", tenant); // To verify the tenant value
  const handleLogin = async (event) => {
    event.preventDefault(); // Prevent the form from refreshing the page
    setIsLoggingIn(true); // Set isLoggingIn to true when login starts
    try {
      const response = await axios.post('/login', {
        username,
        password,
        tenant
      }, { headers: { 'Content-Type': 'application/json', }, withCredentials: true });
      
      if (response.data.msg === "Login successful") {
        console.log(response.data.reply)
        onLogin(response.data.reply);
      }
    } catch (error) {
      setError('Invalid credentials');
    } finally {
      setIsLoggingIn(false); // Set isLoggingIn back to false when login is complete
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <h2 className="login-title">Welcome to MemberCaddy!</h2>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className="login-input"
          autoCapitalize="none"
          autoCorrect="off"
          autoComplete="off"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
        />
        <button type="submit" className="login-button" disabled={isLoggingIn}>
        {isLoggingIn ? 'Logging in...' : 'Login'}
        </button>
      </form>
    </div>
  );
};

export default Login;